const NavBar = () => {
    return (
        <nav>
            <h2>Kirsty Brewster</h2>
            <ul className="horizontal-list">
                <li>
                    <a> About Me </a>
                </li>
                    {/* <ul>
                        <li>What I'm Working On</li>
                    </ul> */}
                <li>
                    <a> Blog </a>
                </li>
                <li>
                    <a> Portfolio </a>
                </li>
                    {/* <ul>
                        <li>Coding</li>
                        <li>Films</li>
                    </ul> */}
                <li>
                    <a> Contact </a>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar;