import github from '../images/github_logo.png'
import linkedin from '../images/linkedin_logo.png'
import twitter from '../images/twitter_logo.png'

const Footer = () => {
    return (
        <footer>
            <div>
                &copy; Kirsty Brewster 2021
            </div>
            <br/>
            <button className="back-to-top">
                &#8679;
            </button>
            <ul className="horizontal-list">
                <li>
                    <a href="https://www.linkedin.com/in/kirstybrewster/" target="_blank"><img className="logo" src={linkedin} alt="LinkedIn Logo"/></a>
                </li>
                <li>
                    <a href="https://github.com/kirstybrews" target="_blank"><img className="logo" src={github} alt="Github Logo"/></a>
                </li>
                <li>
                    <a href="https://twitter.com/KirstyBrewster" target="_blank"><img className="logo" src={twitter} alt="Twitter Logo"/></a>
                </li>
            </ul>
        </footer>
    )
}

export default Footer;