import './App.css';
import NavBar from './components/NavBar';
import AboutMe from './components/AboutMe';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <header>
        <NavBar/>
      </header>
      <hr/>
      <AboutMe/>
      <hr/>
      <Footer/>
    </div>
  );
}

export default App;
