import headshot from '../images/headshot.jpeg';

const AboutMe = () => {
    return (
        <section>
            <h1>About Me</h1>
            <img className="headshot" src={headshot} alt="Photo of Kirsty"/>
            <strong>
                <h2>Hi! My name's Kirsty.</h2>
            </strong>
            <p>I'm a full-stack web developer from Austin, TX. I 
            received my bachelor's degree in psychology from UT - Austin and just 
            graduated from The Flatiron School's software engineering 
            bootcamp!</p>
        </section>
    )
}

export default AboutMe;